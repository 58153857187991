import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getMemberList } from "../../../store/API/MemberApis";
import Pagination from "../../../components/common/Pagination";
import { getGeneralSettings } from "../../../store/API/CommonApis";
import ListBanner from "../../../components/Neta/List/ListBanner";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import SearchNeta from "../../../components/Home/SearchNeta";
import ListSidebar from "../../../components/Neta/List/ListSidebar";
import ListContent from "../../../components/Neta/List/ListContent";

// Define type for filter parameters
export interface Filters {
  division: string | null;
  district: string | null;
  upazila: string | null;
  seat: string | null;
}

const NetaList = () => {
  const { memberList, loading } = useAppSelector((state) => state.member);
  const [remountComponent, setRemountComponent] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [memberType, setMemberType] = useState<string>("");
  const navigate = useNavigate();
  const listContentRef = useRef<HTMLDivElement>(null);

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const limit = 10;

  // State to hold the selected filters
  const [filters, setFilters] = useState<Filters>({
    division: searchParams.get("division"),
    district: searchParams.get("district"),
    upazila: searchParams.get("upazila"),
    seat: searchParams.get("seat"),
  });

  useEffect(() => {
    dispatch(getGeneralSettings());
  }, [dispatch]);

  useEffect(() => {
    // Update filters state based on URL query params
    const division = searchParams.get("division");
    const district = searchParams.get("district");
    const upazila = searchParams.get("upazila");
    const seat = searchParams.get("seat");

    setFilters({ division, district, upazila, seat });

    // Dispatch API calls based on search params
    const queryParams = {
      page: 1,
      page_size: limit,
      division,
      district,
      upazila,
      seat,
    };
    dispatch(getMemberList(queryParams));
  }, [searchParams, dispatch]);

  const handlePageClick = (data: any) => {
    setSelectedPage(data.selected + 1);
    const queryParams = {
      page: data.selected + 1,
      page_size: limit,
      member_type: memberType,
      ...filters,
    };
    dispatch(getMemberList(queryParams));

    const headerOffset = 150;
    if (listContentRef.current) {
      const elementPosition =
        listContentRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  const handleMemberTypeClick = (data: string) => {
    setMemberType(data);
    const queryParams = {
      page: 1,
      page_size: limit,
      member_type: data,
      ...filters,
    };
    dispatch(getMemberList(queryParams));

    setRemountComponent(Math.random());
    setSelectedPage(1);

    const headerOffset = 150;
    if (listContentRef.current) {
      const elementPosition =
        listContentRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  const handleSearchSubmit = (data: any) => {
    setMemberType("")

    const newFilters: Filters = { ...filters };

    if (data?.seat) newFilters.seat = data?.seat?.value;
    if (data?.upazila) newFilters.upazila = data?.upazila?.value;
    if (data?.district) newFilters.district = data?.district?.value;
    if (data?.division) newFilters.division = data?.division?.value;

    // Filter out null or undefined values
    const filteredFilters = Object.fromEntries(
      Object.entries(newFilters).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );

    // Update the URL with search params
    const queryParams = new URLSearchParams(filteredFilters as any);
    console.log({ queryParams });

    navigate(`/neta/list?${queryParams.toString()}`);

    setRemountComponent(Math.random());
    setSelectedPage(1);
  };


  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ListBanner />
      <SearchNeta
        handleSearchSubmit={handleSearchSubmit}
        title="Your Leader"
        filters={filters} // Pass selected filters as props
      />
      <section className="neta_list bg-[#fafbff] lg:py-10 py-8">
        <div className="container-fluid mx-auto">
          <div
            className="flex md:flex-row flex-col items-start gap-5"
            ref={listContentRef}
          >
            <ListSidebar
              memberType={memberType}
              handleMemberTypeClick={handleMemberTypeClick}
            />
            <ListContent
              limit={limit}
              total={memberList?.count}
              selectedPage={selectedPage}
            />
          </div>
          <div className="mt-8 flex justify-end" key={remountComponent}>
            <Pagination
              pageCount={Math.ceil((memberList?.count || 0) / limit)}
              handlePageClick={handlePageClick}
              pageRange={5}
              currentPage={selectedPage - 1}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default NetaList;
